const Routes = {
  LOGIN: '/login',
  HOME: '/home',
  REGISTER: '/register',
  PROFILE: '/profile',

  ADMIN_DASHBOARD: '/dash-board',
  ADMIN_LIST_DEPOSIT: '/list-deposit',
  ADMIN_LIST_PAYOUT: '/list-payout',
  ADMIN_LIST_PAYOUT_TOPUP: '/list-payout-topup',

  ADMIN_LIST_WITHDRAW: '/list-withdraw',
  ADMIN_WITHDRAWAL_FUNDS: '/list-withdrawal-funds',
  ADMIN_LIST_WITHDRAW_ACCOUNT: '/list-withdraw-account',

  ADMIN_LIST_BALANCE_HISTORY: '/list-balance-history',
  ADMIN_LIST_WITHDRAWL_HISTORY: '/list-withdrawal-history',


  ADMIN_LIST_USER: '/list-user',
  ADMIN_DETAIL_USER: '/detail-user',
  ADMIN_CREATE_USER: '/create-user',

  ADMIN_LIST_STAKING: '/list-staking',
  ADMIN_LIST_INVESTING: '/list-investing',

  ADMIN_LIST_HISTORY: '/list-history',


  ADMIN_LIST_HISTORY_DEPOSIT: '/list-history-deposit',
  ADMIN_LIST_HISTORY_WITHDRAW: '/list-history-withdraw',
  ADMIN_LIST_HISTORY_SWAP: '/list-history-swap',
  ADMIN_LIST_HISTORY_TRANSFER: '/list-history-transfer',
  ADMIN_LIST_HISTORY_INVEST: '/list-history-invest',

  ADMIN_LIST_HISTORY_STAKING: '/list-history-staking',

  ADMIN_SETTING: '/setting',
  ADMIN_SETTING_MERCHANT: '/setting-merchant',

  ADMIN_DOCUMENT: '/document',
  ADMIN_PAYOUT_DOCUMENT: '/payout-document',

  ADMIN_CHANGE_PASS: '/change-pass',

  ADMIN_LIST_INVITER: '/list-inviter',

  ADMIN_REPORT: '/report',

  ADMIN_LIST_BONUST: '/list-bonus',


  ADMIN_ERRORS: '/errors',
  WHITE_LIST: '/white-list',
  REGISTERED: '/registered',
  REJECTED: '/rejected',

  TEST: '/test'
}

export default Routes