import styled from 'styled-components'

export const EmptyContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  font-weight: bold;
  font-size: 20px;
  background-color: #eaeaea;
`