const Endpoints = {
  DOWNLOAD: '/report',
  LOGIN: '/api/v1/public/auth/login',
  USER_LIST: '/api/v1/public/user/list',
  USER_LIST_TRADE: '/api/v1/public/user/list-trade',
  USER_PROFILE: '/api/v1/public/auth/profile',
  GET_USER_DETAIL: '/api/v1/public/user/details',
  GET_USER_CREATE: '/api/v1/public/user/create',
  GET_USER_UPDATE: '/api/v1/public/user/update',
  GET_USER_DELETE: '/api/v1/public/user/delete',
  GET_USER_CHANGE_PASS: '/api/v1/public/auth/change-password',
  GET_SETTING: '/api/v1/public/settings/details',
  UPDATE_SETTING: '/api/v1/public/settings/update',
  CONFIRM_KYC: '/api/v1/public/user/confirm-kyc',
  UPDATE_SETTING_TELEGRAM: '/api/v1/public/user/telegram',

  GET_HISTORY_BALANCE: '/api/v1/public/user-update-balance/list',
  GET_DEPOSIT_HISTORY_LIST: '/api/v1/public/deposit-history/list-by-user',
  GET_WITHDRAW_HISTORY_LIST: '/api/v1/public/withdraw-history/list-by-user',
  GET_CONFIRM_WITHDRAW_HISTORY: '/api/v1/public/withdraw-history/confirm',
  GET_SWAP_HISTORY_LIST: '/api/v1/public/swap-history/list-by-user',
  GET_TRANSFER_HISTORY_LIST: '/api/v1/public/transfer-history/list-by-user',
  GET_INVEST_HISTORY_LIST: '/api/v1/public/user-package/list-invest-by-user',
  GET_STAKING_HISTORY_LIST: '/api/v1/public/user-package/list-stake-by-user',
  GET_PAYOUT_STATUS: '/api/v1/public/transaction/payout/status',
  GET_AGENT_HISTORY_WITHDRAW: '/api/v1/public/transaction/agent/withdraw/list',
  GET_AGENT_CREATE_WITHDRAW: '/api/v1/public/transaction/agent/withdraw/create',

  GET_STAKING_LIST: '/api/v1/public/package/list-staking',
  GET_INVESTING_LIST: '/api/v1/public/package/list-investing',
  GET_PACKAGE_UPDATE: '/api/v1/public/package/update',

  GET_BONUS_LIST: '/api/v1/public/bonus/list-bonus-by-user',
  GET_BONUS_UPDATE: '/api/v1/public/user/update-bonus',

  CHANGE_PASSWORD: '/api/v1/public/auth/change-password',

  GET_ALL_MEMBER: '/api/v1/admin/list-user',

  GET_ALL_COUNTRY: '/api/v1/public/country/list',

  GET_ALL_CONTACT: '/api/v1/public/contact/getAll',

  GET_LIST_BANK: '/api/v1/public/user-bank/list',
  GET_CREATE_BANK: '/api/v1/public/user-bank/create',
  GET_ADD_BANK: '/api/v1/public/user-bank/add',
  GET_UPDATE_BANK: '/api/v1/public/user-bank/update',

  GET_LIST_TRANSACTION: '/api/v1/public/transaction/list',
  GET_LIST_TRANSACTION_ADMIN: '/api/v1/public//transaction/list-by-user',

  GET_DASHBOARD_STATISTIC: '/api/v1/public/dashboard/statistic',

}

export default Endpoints