const reactLocalStorage = {
  set: function (key: string, value: any) {
    localStorage[key] = value;
    return localStorage[key];
  },
  get: function (key: string, defaultValue: any) {
    return localStorage[key] || defaultValue;
  },
  setObject: function (key: string, value: any) {
    localStorage[key] = JSON.stringify(value);
    return localStorage[key];
  },
  getObject: function (key: string, value: any) {
    return JSON.parse(localStorage[key] || '{}');
  },
  clear: function () {
    return localStorage.clear();
  }
}

export default reactLocalStorage;