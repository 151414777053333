import { useSelector } from 'react-redux'
import { RootState } from 'redux/reducer'
function usePopup() {
  const {
    isPopup, 
    type,
    message
  } = useSelector((state: RootState) => state.popup)

  return {
    isPopup, 
    type,
    message
  }
}

export default usePopup
