import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../redux/store";
import { getListBank } from "services/Bank";

interface PopupState {
  dataBank: Array<any>;
}

const listItialState: PopupState = {
  dataBank: [],
};

const listBank = createSlice({
  name: "ListBank",
  initialState: listItialState,
  reducers: {
    setListBank(state, { payload }: PayloadAction<any>) {
      state.dataBank = payload;
    },
  },
});

export const { setListBank } = listBank.actions;

export const fetchListBank = (): AppThunk => async (dispatch) => {
  try {
    const response = await getListBank();
    if (response?.result) {
      dispatch(setListBank(response?.data));
    }
  } catch (error) {
      dispatch(setListBank([]));
  }
};

export default listBank.reducer;
