import { ENV } from 'configs/constraints'
export default function ApiConfig() {
  const configs = {
    development: {
      BASE_URL: process.env.REACT_APP_SERVER_RESERVE_URL
    },
    staging: {
      BASE_URL: process.env.REACT_APP_SERVER_RESERVE_URL
    },
    production: {
      BASE_URL: process.env.REACT_APP_SERVER_RESERVE_URL
    }
  }

  return configs[ENV]
}
