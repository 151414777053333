import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import { ConfigProvider } from "antd";
import App from "./App";
import "antd/dist/antd.css";
// import * as serviceWorker from './serviceWorker';
import ModalProvider from "components/Modal/ModalContext";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_vn from "./locales/translation/vn/translation.json";
import common_en from "./locales/translation/en/translation.json";
import common_cn from "./locales/translation/cn/translation.json";

const history = createBrowserHistory();

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "vn", // language to use
  resources: {
    vn: {
      common: common_vn,
    },
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    cn: {
      common: common_cn, // 'common' is our custom namespace
    },
  },
});

ConfigProvider.config({
  theme: {
    primaryColor: '#696cff',
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ModalProvider>
      <PersistGate persistor={persistor} loading={null}>
        <Router history={history}>
          <I18nextProvider i18n={i18next}>
            <ConfigProvider>
              <App />
            </ConfigProvider>
          </I18nextProvider>
        </Router>
      </PersistGate>
    </ModalProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
