import React, {useState, useEffect, memo} from "react";
import { useDispatch } from 'react-redux'
import { createBrowserHistory } from 'history'
import {
  AppBar,
  Toolbar,
  Button,
  IconButton
} from "@material-ui/core";
import UserWallet from 'components/Menu/UserWallet'

import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PersonIcon from '@material-ui/icons/Person';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CloseIcon from '@material-ui/icons/Close';

import Routes from 'constants/routes'
import { logOut } from 'features/user/userSlice'
import reactLocalStorage from 'utils/localStorage'

import * as S from "./style"

interface Props {
  handleDrawerOpen?: () => void;
  langSelected?: (item:any) => void;
  isOpen?: boolean;
  listOptions?: any;
}

function Header(props: Props) {
  const { handleDrawerOpen, isOpen, listOptions, langSelected  } = props;
  const dispatch = useDispatch()
  const history = createBrowserHistory()
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [langActive, setLangActive] = useState(listOptions[0])

  const onLogOut = () => {
    dispatch(logOut())
    reactLocalStorage.clear()
    history.push(Routes.LOGIN)
    window.location.reload()
  }

  const onHandleClick = (item) => {
    setLangActive(item)
    // i18n.changeLanguage(item.lang)
    langSelected(item)
    setActiveDropdown(false)
  }

  useEffect(() => {
    let lang = JSON.parse(reactLocalStorage.get("langBank", JSON.stringify(langActive)))
    setLangActive(lang)
    // eslint-disable-next-line
  }, []);

  return (
    <S.Header>
      <AppBar position="fixed" className="app-bar">
          <Toolbar className="navbar-dashboard">
            <div className="left-navbar">
              <IconButton
                color="default"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={`btn-action-sidebar ${
                  isOpen === true ? "btn-open" : "btn-close"
                }`}
              >
                {isOpen === true ? (
                  <FormatListBulletedIcon className="arrow" />
                ) : (
                  <MoreVertIcon className="arrow" />
                )}
              </IconButton>
            </div>
            
            <div className="right-navbar">
            {/* <UserWallet account={account} login={login} logout={logout} /> */}
              {/* <Button
                variant="contained"
                color="primary"
                className="icon-heading"
                // onClick={getNotification}
              >
                <NotificationsIcon/>
              </Button> */}
              
              <Button
                variant="contained"
                color="primary"
                className="icon-heading"
                // onClick={()=> history.push(Routes.PROFILE)}
                href={Routes.PROFILE}
              >
                <PersonIcon/>
              </Button>
              <S.Languages>
                <div className="language">
                  <div className="language-block" onClick={() => setActiveDropdown(!activeDropdown)}>
                    <img
                      src={langActive.src}
                      alt="language"
                    />
                  </div>
                  {activeDropdown ?
                    <ul className="pop-content">
                      <li className="close-btn" onClick={() => setActiveDropdown(!activeDropdown)}>
                        <CloseIcon />
                      </li>

                      {
                        listOptions.map((item, index) => {
                          return (
                            <li key={index}>
                              <button onClick={() => onHandleClick(item)}>
                                <img
                                  src={item.src}
                                  alt={item.name}
                                />
                                <span>{item.name}</span>
                              </button>
                            </li>
                          )
                        })
                      }
                    </ul>
                    : ''}
                </div>
              </S.Languages>
            </div>
          </Toolbar>
        </AppBar>
      </S.Header>
  );
}

export default memo(Header);
