import styled from "styled-components";

export const StyleItem = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: flex-start;
  text-decoration: none;
`;

export const StyleItemIcon = styled.div`
  color: rgba(0, 0, 0, 0.54);
  display: inline-flex;
  min-width: 18px;
  flex-shrink: 0;
`;

export const StyleItemText = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 5px;
  /* font-size: 1rem; */
`;
