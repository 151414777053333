import styled from "styled-components";

export const AccordionStyled = styled.div`
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
`;

export const Container = styled.div`
  .rdw-editor-wrapper{
    min-height: 350px;
    border: 1px solid #ccc;
  }
  .public-DraftEditor-content {
    min-height: 300px;
  }
`;
