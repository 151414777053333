import React, { useState, useEffect, memo } from "react";
import {
  LineChartOutlined,
  UserOutlined,
  UserAddOutlined,
  LockOutlined,
  BankOutlined,
  CodepenCircleOutlined,
  CreditCardOutlined,
  RiseOutlined,
  FallOutlined,
  SwapOutlined,
  TransactionOutlined,
  SettingOutlined,
  BlockOutlined,
  PicCenterOutlined,
  FileWordOutlined,
  DashboardOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import {isMobile} from 'react-device-detect';
import Avatar from "assets/images/faces/avatar.jpg";
import useUser from "hooks/useUser";

import * as S from "./style";
import { SideBar, Header } from "components";
import { useTranslation } from "react-i18next";
import Routes from "constants/routes";
import VN from "assets/images/flags/VN.png";
import En from "assets/images/flags/GB.png";
import Cn from "assets/images/flags/CN.png";

import reactLocalStorage from "utils/localStorage";
interface Props {
  boardContent?: any;
  getInfoWallet?: () => void;
}

const Sidebar = () => {
  const { t } = useTranslation("common");

  const dataSideBar = [
    {
      titleMenu: t("DASHBOARD"),
      icon: <DashboardOutlined />,
      link: Routes.ADMIN_DASHBOARD,
      subMenu: null,
    },
    {
      titleMenu: t("DEPOSIT"),
      icon: <BankOutlined />,
      link: Routes.ADMIN_LIST_DEPOSIT,
      subMenu: null,
    },
    // {
    //   titleMenu: t("PAYOUT"),
    //   icon: <CreditCardOutlined />,
    //   link: Routes.ADMIN_LIST_PAYOUT,
    //   subMenu: [
    //     {
    //       titleMenu: t("TRANSACTION"),
    //       icon: <TransactionOutlined />,
    //       // link: Routes.ADMIN_MEMBER_LIST + `/0`,
    //       link: Routes.ADMIN_LIST_PAYOUT,
    //       subMenu: null,
    //     },
    //     {
    //       titleMenu: t("TOPUP"),
    //       icon: <RiseOutlined />,
    //       link: Routes.ADMIN_LIST_PAYOUT_TOPUP,
    //       subMenu: null,
    //     },
    //   ],
    // },

    {
      titleMenu: t("PAYOUT"),
      icon: <CreditCardOutlined />,
      link: Routes.ADMIN_LIST_WITHDRAW,
      subMenu: [
        {
          titleMenu: t("TRANSACTION"),
          icon: <TransactionOutlined />,
          // link: Routes.ADMIN_MEMBER_LIST + `/0`,
          link: Routes.ADMIN_LIST_WITHDRAW,
          subMenu: null,
        },
        {
          titleMenu: t("ACCOUNT"),
          icon: <UserOutlined />,
          link: Routes.ADMIN_LIST_WITHDRAW_ACCOUNT,
          subMenu: null,
        },
      ],
    },
    {
      titleMenu: t("WITHDRAWL_OF_FUNDS"),
      icon: <CreditCardOutlined />,
      link: Routes.ADMIN_WITHDRAWAL_FUNDS,
      subMenu: null,
    },
    {
      titleMenu: t("BALANCE_HISTORY"),
      icon: <CodepenCircleOutlined />,
      link: Routes.ADMIN_LIST_BALANCE_HISTORY,
      subMenu: null,
    },

    {
      titleMenu: t("WITHDRAWL_HISTORY"),
      icon: <CodepenCircleOutlined />,
      link: Routes.ADMIN_LIST_WITHDRAWL_HISTORY,
      subMenu: null,
    },

    {
      titleMenu: t("SETTING") ,
      icon: <SettingOutlined />,
      link: Routes.ADMIN_SETTING,
      subMenu: null
      // subMenu: [
      //   {
      //     titleMenu: t("NOTIFY"),
      //     icon: <NotificationOutlined  />,
      //     // link: Routes.ADMIN_MEMBER_LIST + `/0`,
      //     link: Routes.ADMIN_SETTING,
      //     subMenu: null,
      //   },
      //   {
      //     titleMenu: t("MERCHANT"),
      //     icon: <UserOutlined />,
      //     link: Routes.ADMIN_SETTING_MERCHANT,
      //     subMenu: null,
      //   },
      // ],
    },
    {
      titleMenu: t(`DOCUMENT`),
      icon: <FileWordOutlined />,
      link: Routes.ADMIN_DOCUMENT,
      subMenu: [
        {
          titleMenu: t("DEPOSIT"),
          icon: <BankOutlined  />,
          // link: Routes.ADMIN_MEMBER_LIST + `/0`,
          link: Routes.ADMIN_DOCUMENT,
          subMenu: null,
        },
        {
          titleMenu: t("PAYOUT"),
          icon: <CreditCardOutlined />,
          link: Routes.ADMIN_PAYOUT_DOCUMENT,
          subMenu: null,
        },
      ],
    },
    {
      titleMenu: t("CHANGE_PASSWORD"),
      icon: <LockOutlined />,
      link: Routes.ADMIN_CHANGE_PASS,
      subMenu: null,
    },
  ];

  return dataSideBar
};
const dataSidebar = [
  {
    titleMenu: `List User`,
    icon: <UserOutlined />,
    link: Routes.ADMIN_LIST_USER,
    subMenu: null,
  },
  {
    titleMenu: "Create a new User",
    icon: <UserAddOutlined />,
    link: Routes.ADMIN_CREATE_USER,
    subMenu: null,
  },
  {
    titleMenu: `List Staking`,
    icon: <CodepenCircleOutlined />,
    link: Routes.ADMIN_LIST_STAKING,
    subMenu: null,
  },
  {
    titleMenu: `List Investing`,
    icon: <LineChartOutlined />,
    link: Routes.ADMIN_LIST_INVESTING,
    subMenu: null,
  },
  {
    titleMenu: `List History`,
    icon: <BankOutlined />,
    link: Routes.ADMIN_LIST_HISTORY_DEPOSIT,
    subMenu: [
      {
        titleMenu: `Deposit`,
        icon: <RiseOutlined />,
        // link: Routes.ADMIN_MEMBER_LIST + `/0`,
        link: Routes.ADMIN_LIST_HISTORY_DEPOSIT,
        subMenu: null,
      },
      {
        titleMenu: `Withdraw`,
        icon: <FallOutlined />,
        link: Routes.ADMIN_LIST_HISTORY_WITHDRAW,
        subMenu: null,
      },
      {
        titleMenu: `Swap`,
        icon: <SwapOutlined />,
        link: Routes.ADMIN_LIST_HISTORY_SWAP,
        subMenu: null,
      },
      {
        titleMenu: `Transfer`,
        icon: <TransactionOutlined />,
        link: Routes.ADMIN_LIST_HISTORY_TRANSFER,
        subMenu: null,
      },
      {
        titleMenu: `Invest`,
        icon: <BlockOutlined />,
        link: Routes.ADMIN_LIST_HISTORY_INVEST,
        subMenu: null,
      },
      {
        titleMenu: `Staking`,
        icon: <PicCenterOutlined />,
        link: Routes.ADMIN_LIST_HISTORY_STAKING,
        subMenu: null,
      },
    ],
  },
  {
    titleMenu: `Setting`,
    icon: <SettingOutlined />,
    link: Routes.ADMIN_SETTING,
    subMenu: null,
  },
  {
    titleMenu: `Change password`,
    icon: <LockOutlined />,
    link: Routes.ADMIN_CHANGE_PASS,
    subMenu: null,
  },
];

function DefaultLayout(props: Props) {
  const { boardContent } = props;
  const { t } = useTranslation("common");
  const { i18n } = useTranslation("common");
  const dfOpen = isMobile ? false : true
  const [open, setOpen] = useState(dfOpen);
  // const { isAuthenticated, role } = useUser();

  const listOptions = [
    {
      src: VN,
      name: "Việt Nam",
      lang: "vn",
    },
    {
      src: En,
      name: "English",
      lang: "en",
    },
    {
      src: Cn,
      name: "中文",
      lang: "cn",
    },
  ];

  
  const userInfo = {
    avtSrc: Avatar,
    useName: "Tania Andrew",
    subInfo: [
      {
        id: 1,
        codeName: "L",
        pageName: `${t("SIDEBAR_LOGOUT")}`,
        link: "#",
        isActive: false,
      },
    ],
  };

  const dataSideBars = dataSidebar;

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const getLangSelected = (e) => {
    i18n.changeLanguage(e.lang);
    reactLocalStorage.set("langBank", JSON.stringify(e));

    // console.log("default", e)
  };

  useEffect(() => {
    let langSelected = JSON.parse(
      reactLocalStorage.get("langBank", JSON.stringify(listOptions[0]))
    ).lang;
    i18n.changeLanguage(langSelected);
    // eslint-disable-next-line
  }, []);

  return (
    <S.Wrapper>
      <Header
        handleDrawerOpen={handleDrawerOpen}
        langSelected={getLangSelected}
        isOpen={open}
        listOptions={listOptions}
      />
      <S.Body>
        <SideBar
          userInfo={userInfo}
          sideBarMenu={Sidebar()}
          open={open}
        // infoWallet={infoWallet}
        />
        <main className="main-content">
          <div className="board-content">{boardContent}</div>
        </main>
      </S.Body>
    </S.Wrapper>
  );
}

export default memo(DefaultLayout);
