import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../../redux/store'

import { User, userLogin as logInService } from 'services/User'
interface UserState {
  userid: string,
  msgCode: string | null,
  isLoading: boolean,
  email: string,
  accessToken: string | null,
  error: object | null,
  role: string | null
  isPaidForCopyTrade?: any
  bonusDebt?: any
}

const userInitialState: UserState = {
  userid: '',
  isLoading: false,
  msgCode: '',
  email: '',
  accessToken: '',
  error: null,
  role: '',
  isPaidForCopyTrade: 0,
  bonusDebt: 0
}

function startLoading(state: UserState) {
  state.isLoading = true
}

function loadingFailed(state: UserState | undefined, action: PayloadAction<any>) {
  state.isLoading = false
  state.error = action.payload
}

function reset(state: UserState) {
  state.msgCode = null
  state.isLoading = false
}

const user = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    startLogIn: startLoading,
    setUser(state, { payload }: PayloadAction<User>) {
      const { 
        userid, 
        accessToken, 
        email, 
        role,
        isPaidForCopyTrade,
        bonusDebt
      } = payload
      state.userid = userid
      state.accessToken = accessToken
      state.email = email
      state.isLoading = false
      state.role = role
      state.isPaidForCopyTrade = isPaidForCopyTrade
      state.bonusDebt = bonusDebt
      
    },
    logInFailure: loadingFailed,
    logOut: (_state) => { },
    resetState: reset
  }
})

export const {
  setUser,
  startLogIn,
  logInFailure,
  logOut,
  resetState
} = user.actions

export const login = (formData: object): AppThunk => async (dispatch) => {
  try {
    dispatch(startLogIn())
    const response = await logInService(formData)
    console.log('response123:::', response);
    
    dispatch(setUser(response.data))
  } catch (error) {
    dispatch(logInFailure(error))
  }
}

export default user.reducer
