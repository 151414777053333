import { useSelector } from 'react-redux'
import { RootState } from 'redux/reducer'
function useTypeMember() {
  const {
    typeMember
  } = useSelector((state: RootState) => state.typeMember)

  return {
    typeMember
  }
}

export default useTypeMember
