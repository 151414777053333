/**
*
* SystemAlert
*
*/

import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const toastConfigs = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
}

function SystemAlert({alerts}) {
  React.useEffect(() => {
    switch (alerts.type) {
      case 'success':
        toast.success(alerts.message, toastConfigs)
        break
      case 'error':
        toast.error(alerts.message, toastConfigs)
        break
      default:
        return
    }
  }, [alerts])
  return <ToastContainer />
}

export default SystemAlert