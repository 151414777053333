import React, { Suspense, lazy, useEffect } from "react";
import styled from "styled-components";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import Routes from "constants/routes";
import useUser from "hooks/useUser";
import GlobalStyles from "./global-styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { LoadSpinner } from "components";
import { DefaultLayout } from "layouts";
import Loading from "components/Loading";
import { fetchListBank } from "features/listBank";
import { fetchInfoProfile } from "features/profile";

const LoginPage = lazy(() => import("pages/LoginPage"));

const Dashboard = lazy(() => import("pages/Dashboard"));

const UserManage = lazy(() => import("pages/UserManage"));
const StakingManage = lazy(() => import("pages/StakingManage"));
const InvestingManage = lazy(() => import("pages/InvestingManage"));
const HistoryManage = lazy(() => import("pages/HistoryManage"));
const DepositManage = lazy(() => import("pages/DepositManage"));
const SwapManage = lazy(() => import("pages/HistoryManage/Swap"));
const TransferManage = lazy(() => import("pages/HistoryManage/Transfer"));
const Invest = lazy(() => import("pages/HistoryManage/Invest"));
const Staking = lazy(() => import("pages/HistoryManage/Staking"));

const PayoutManage = lazy(() => import("pages/PayoutManage"));
const PayoutManageTopup = lazy(() => import("pages/PayoutManage/Topup"));
const WithdrawManage = lazy(() => import("pages/WithdrawManage"));
const WithdrawManageAccount = lazy(
  () => import("pages/WithdrawManage/Account")
);

const BalanceHistory = lazy(() => import("pages/BalanceHistory"));

const WithdrawalHistory = lazy(() => import("pages/WithdrawalHistory"));
const SettingManage = lazy(() => import("pages/SettingManage"));
const MerchantManage = lazy(() => import("pages/SettingManage/Merchant"));
const ChangePassword = lazy(() => import("pages/ChangePassword"));
const Profile = lazy(() => import("pages/Profile"));
const DocumentManage = lazy(() => import("pages/DocumentManage"));
const PayoutDocumentManage = lazy(() => import("pages/DocumentManage/Payout"));
const WithdrawlFundsManage = lazy(() => import("pages/WithdrawlFundsManage"));

const Errors = lazy(() => import("pages/Errors"));

const renderLoader = () => <LoadSpinner isOverlay={false} />;

const AppWrapper = styled.div`
  height: 100%;
  .ant-layout {
    height: 100%;
    .ant-layout-content {
      background-color: ${(props) => props.theme.innerContBg};
    }
  }
  .ant-layout-header {
    background: #ffffff;
    height: auto;
    padding: 0;
    line-height: inherit;
    z-index: 99;
    position: sticky;
    top: 0;
  }
`;

function App() {
  const location = useLocation();
  const { isAuthenticated, role } = useUser();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   isAuthenticated && dispatch(fetchNetwork())
  // }, [dispatch,isAuthenticated])

  useEffect(() => {
    // getApiListHistory();
    isAuthenticated && dispatch(fetchInfoProfile());
  }, [isAuthenticated]);

  function PrivateRoute({ component: TargetPage, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated === true ? (
            <TargetPage {...props} />
          ) : (
            <Redirect to={Routes.LOGIN} />
          )
        }
      />
    );
  }

  function AdminRoute({ component: TargetPage, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated === true && role === "ADMIN" ? (
            <TargetPage {...props} />
          ) : (
            <Redirect to={Routes.LOGIN} />
          )
        }
      />
    );
  }

  function PublicRoute({
    component: TargetPage,
    ...rest
  }: any): React.ReactElement {
    return (
      <Route
        {...rest}
        render={(props: any) => {
          return <TargetPage {...props} {...rest} />;
        }}
      />
    );
  }
  return (
    <AppWrapper>
      {isAuthenticated === false ? (
        <Switch>
          <Suspense fallback={renderLoader()}>
            <Redirect exact from="/" to={Routes.LOGIN} />
            <Route path={Routes.LOGIN} component={LoginPage} />
          </Suspense>
        </Switch>
      ) : (
        <DefaultLayout
          // pageHeading={t("STATISCAL_HEADING")}
          boardContent={
            <Switch>
              <Suspense fallback={renderLoader()}>
                <Route path={Routes.LOGIN} component={LoginPage} />
                {location.pathname === "/login" && role !== "USER" && (
                  <Redirect exact from="/login" to={Routes.ADMIN_DASHBOARD} />
                )}
                <Route
                  exact
                  path="/"
                  render={() => {
                    return <Redirect to={Routes.ADMIN_DASHBOARD} />;
                  }}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_DASHBOARD}
                  component={Dashboard}
                />
                <AdminRoute
                  exact
                  path={Routes.ADMIN_LIST_USER}
                  component={UserManage}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_DEPOSIT}
                  component={DepositManage}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_PAYOUT}
                  component={PayoutManage}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_PAYOUT_TOPUP}
                  component={PayoutManageTopup}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_WITHDRAW}
                  component={WithdrawManage}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_WITHDRAW_ACCOUNT}
                  component={WithdrawManageAccount}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_BALANCE_HISTORY}
                  component={BalanceHistory}
                />

                <PrivateRoute exact path={Routes.PROFILE} component={Profile} />
                <PrivateRoute exact path={Routes.ADMIN_WITHDRAWAL_FUNDS} component={WithdrawlFundsManage} />
                <PrivateRoute exact path={Routes.ADMIN_LIST_WITHDRAWL_HISTORY} component={WithdrawalHistory} />
                
                
                {/* 
                <AdminRoute
                  exact
                  path={Routes.ADMIN_LIST_STAKING}
                  component={StakingManage}
                />
                <AdminRoute
                  exact
                  path={Routes.ADMIN_LIST_INVESTING}
                  component={InvestingManage}
                />
                <AdminRoute
                  exact
                  path={Routes.ADMIN_LIST_HISTORY}
                  component={HistoryManage}
                />
                <AdminRoute
                  exact
                  path={Routes.ADMIN_LIST_HISTORY_DEPOSIT}
                  component={DepositManage}
                />
                <AdminRoute
                  exact
                  path={Routes.ADMIN_LIST_HISTORY_WITHDRAW}
                  component={WithdrawManage}
                />
                <AdminRoute
                  exact
                  path={Routes.ADMIN_LIST_INVITER}
                  component={InviteManage}
                />
                <AdminRoute
                  exact
                  path={Routes.ADMIN_LIST_HISTORY_SWAP}
                  component={SwapManage}
                />
                <AdminRoute
                  exact
                  path={Routes.ADMIN_LIST_HISTORY_TRANSFER}
                  component={TransferManage}
                />
                <AdminRoute
                  exact
                  path={Routes.ADMIN_LIST_HISTORY_INVEST}
                  component={Invest}
                />
                <AdminRoute
                  exact
                  path={Routes.ADMIN_LIST_HISTORY_STAKING}
                  component={Staking}
                /> */}

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_SETTING}
                  component={SettingManage}
                />
                {/* <PrivateRoute
                  exact
                  path={Routes.ADMIN_SETTING_MERCHANT}
                  component={MerchantManage}
                /> */}
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_DOCUMENT}
                  component={DocumentManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PAYOUT_DOCUMENT}
                  component={PayoutDocumentManage}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_CHANGE_PASS}
                  component={ChangePassword}
                />

                <AdminRoute
                  exact
                  path={Routes.ADMIN_ERRORS}
                  component={Errors}
                />
              </Suspense>
            </Switch>
          }
        />
      )}
      <Loading />
      <GlobalStyles />
      <CssBaseline />
    </AppWrapper>
  );
}

export default App;
