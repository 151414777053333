import React, {useState} from "react";
import { Collapse } from 'antd';
import { Link, useLocation } from "react-router-dom";
import { RightOutlined, DownOutlined  } from "@ant-design/icons";
 
import * as S from './style';


interface Props {
  listData?: any;
  selected?: any;
}

function Listitems({
  listData,
  selected
}: Props): React.ReactElement {
  // const [subSelected, setSubSelected] = useState("");
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
    // setSubSelected(location.pathname)
  };

  const subSelected = location.pathname

  // useEffect(() => {
  //   setSubSelected(location.pathname)
  // }, [])

  return (
    <S.StyleItem
      className={`${
        selected === listData.link ? "active" : "unActive"
      } list-item`}
    >
      {listData.subMenu !== null ? (
        <Link to={listData.link} className="navigate-menu" onClick={handleClick}>
          <S.StyleItemIcon>{listData.icon}</S.StyleItemIcon>
          <S.StyleItemText className="hiden-on-close">
            {listData.titleMenu}
          </S.StyleItemText>
          {open ? (
            <DownOutlined className="hiden-on-close arrow" />
          ) : (
            <RightOutlined className="hiden-on-close arrow" />
          )}
        </Link>
      ) : (
        <Link to={listData.link} className="navigate-menu">
          <S.StyleItemIcon>{listData.icon}</S.StyleItemIcon>
          <S.StyleItemText className="hiden-on-close">
            {listData.titleMenu}
          </S.StyleItemText>
        </Link>
      )}
      <div className="list-subMenu" style={{padding: "0 15px"}}>
        {
        // listData.isActive &&
          listData.subMenu && open &&
    
         listData.subMenu.map((submenuItem, i) => {
            return (
              <S.StyleItem
                className={`${
                  subSelected === submenuItem.link ? "active" : "unActive"
                } list-item`}
                key={i}
              >
              <Link
                to={submenuItem.link}
                className="sub-info navigate-menu"
              >
                <S.StyleItemIcon>{submenuItem.icon}</S.StyleItemIcon>
                <S.StyleItemText className="hiden-on-close sub-info-item">
                  {submenuItem.titleMenu}
                </S.StyleItemText>
              </Link>
            </S.StyleItem>
            );
          })}
          
      </div>
    </S.StyleItem>
  )
}

export default Listitems;