// WorkSans
import WorkSansLight from './WorkSans-Light.ttf'
import WorkSansRegular from './WorkSans-Regular.ttf'
import WorkSansMedium from './WorkSans-Medium.ttf'
import WorkSansSemiBold from './WorkSans-SemiBold.ttf'
import WorkSansBold from './WorkSans-Bold.ttf'
import RussoOneRegular from './RussoOne-Regular.ttf'


const Fonts = {
  WorkSansLight,
  WorkSansRegular,
  WorkSansMedium,
  WorkSansSemiBold,
  WorkSansBold,
  RussoOneRegular
}
export default Fonts
