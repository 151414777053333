import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../../redux/store'

interface PopupState {
  isPopup: boolean,
  type?: string
  message?: string
}

const popupInitialState: PopupState = {
  isPopup: false,
  type: '',
  message: ''
}



const popup = createSlice({
  name: 'Popup',
  initialState: popupInitialState,
  reducers: {
    setPopup(state, { payload }: PayloadAction<any>) {
      const { 
        type,
        message
      } = payload
      state.isPopup = true
      state.type = type
      state.message = message
    },
    hidePopup(state) {
      state.isPopup = false
      state.type = ''
      state.message = ''
    },
  }
})

export const {
setPopup,
hidePopup
} = popup.actions

export const fetchPopup = (formData: object): AppThunk => async (dispatch) => {
  // dispatch(setPopup())

}

export default popup.reducer
