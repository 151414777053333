import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../redux/store";
import { getProfile } from "services/User";

interface DataState {
  dataProfile: Object;
}

const listItialState: DataState = {
  dataProfile: {},
};

const getInfoProfile = createSlice({
  name: "GetProfile",
  initialState: listItialState,
  reducers: {
    setInfoProfile(state, { payload }: PayloadAction<any>) {
      state.dataProfile = payload;
    },
  },
});

export const { setInfoProfile } = getInfoProfile.actions;

export const fetchInfoProfile = (): AppThunk => async (dispatch) => {
  try {
    const response = await getProfile();
    if (response?.result) {
      dispatch(setInfoProfile(response?.data));
    }
  } catch (error) {
    dispatch(setInfoProfile([]));
  }
};

export default getInfoProfile.reducer;
