import { useSelector } from 'react-redux'
import { RootState } from 'redux/reducer'
function useUser() {
  const {
    userid,
    msgCode,
    accessToken,
    email,
    isLoading,
    error,
    role,
    isPaidForCopyTrade,
    bonusDebt
  } = useSelector((state: RootState) => state.user)

  return {
    isAuthenticated: !!accessToken,
    userid,
    msgCode,
    accessToken,
    email,
    isLoading,
    error,
    role,
    isPaidForCopyTrade,
    bonusDebt
  }
}

export default useUser
