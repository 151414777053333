import React from "react";
import * as S from "./style";

interface Props {
  emtyMessage?: string;
}

function EmptyFilter(props: Props) {
  const { emtyMessage } = props;

  return (
    <S.EmptyContent>
      {emtyMessage}
    </S.EmptyContent>
  );
}

export default EmptyFilter;
