import React, { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import * as S from "./style";



interface Props {
  onChangeValue?: (e: any) => void;
  dataDefault?: string;
}



function EditorComponent({ onChangeValue, dataDefault}: Props): React.ReactElement {
  // const [value, setValue] = useState<any>(EditorState.createEmpty());
  const [value, setValue] = useState<any>(EditorState.createEmpty());

  useEffect(() => {
    const toDraft = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(dataDefault)
      ))

      dataDefault && setValue(toDraft)
  }, [dataDefault])
  const handleChange = editorState => {

    const cover = convertToRaw(editorState.getCurrentContent())

    const toHtml = draftToHtml(cover).toString()
    // const toDraft = htmlToDraft(toHtml)
    setValue(editorState)
    onChangeValue && onChangeValue(toHtml)

  }
  // const cover = convertToRaw(value.getCurrentContent())
  // console.log(`cover`, cover)
  return (
    <S.Container>
      <Editor
        editorState={value}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={handleChange}
      />
      {/* {draftToHtml(cover)} */}

    </S.Container>
  );
}

export default EditorComponent;
